require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// tools js
import 'jquery';
import 'swagger-ui';

// project js files
import './main';
import './docs';
